
export const prototypeDialogTableOption = {
  searchText: '搜索',
  isSearchIcon: false,
  searchSpan: 10,
  topPage: false,
  menu: false,
  selection: true,
  column: [
    {
      label: '款式',
      prop: 'structName',
      hide: true,
      search: true
    },
    {
      label: '原型分类',
      prop: 'productCategoryId',
      hide: true,
      search: true,
      type: 'cascader',
      dicType: 'proCategory'
    },
    {
      label: '原型名称/图片',
      prop: 'imgText',
      slot: true
    },
    {
      label: '原型分类',
      prop: 'productCategoryId',
      type: 'cascader',
      dicType: 'proCategory'
    },
    {
      label: '款式',
      prop: '$styleName'
    },
    {
      label: '尺码',
      prop: '$sizeName'
    }
  ]
}

export const pictureDialogTableOption = {
  searchText: '搜索',
  isSearchIcon: false,
  searchSpan: 10,
  topPage: false,
  menu: false,
  selection: true,
  column: [
    {
      label: '图片名称',
      prop: 'title',
      search: true
    },
    {
      label: '图片',
      prop: 'thumbnailPath',
      slot: true
    },
    {
      label: '图片分类',
      prop: 'categoryId',
      search: true,
      type: 'cascader',
      dicType: 'category'
    }
  ]
}