<template>
  <div class="inline-block">
    <el-button :loading="loading" type="primary" size="mini" @click="to"> {{ option.btnTxt }} </el-button>
    <ToggleGroupDialog
      v-if="dialogVisible"
      :type="type"
      :resetMergeData="resetMergeData"
      :visible.sync="dialogVisible"
      v-model="value"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import ToggleGroupDialog from './module/toggleGroupDialog'
import { commonEheaderMixin } from '@/mixins'
import { getOption } from './const'

export default {
  mixins: [commonEheaderMixin],
  components: { ToggleGroupDialog },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    type: {
      type: String,
      default: 'default'
    }
  },

  data() {
    return {
      value: null,
      loading: false,
      selectedList: []
    }
  },

  computed: {
    option({ type }) {
      return getOption(type)
    },

    resetMergeData({ option }) {
      return option.resetMergeData || {}
    },

    getGroupInfo({ option }) {
      return option.getGroupInfo
    }
  },

  methods: {
    async to() {
      this.value = null
      if(this.getGroupInfo) {
       this.value = await this.getGroupInfo.call(this)
      } 
      this.dialogVisible = true
    }
  }
}
</script>
