<template>
  <div class="dialog-warpper">
    <el-dialog title="查看详情" :visible.sync="visible" width="800px" :before-close="handleClose" top="10vh">
      <div class="content-warpper">
        <div class="basic-information-warpper">基础信息</div>
        <div class="flex">
          <span class="count">定制产品名称：{{ handleCnName(product) }}</span>
          <span class="count">定制产品英文名称：{{ handleEnName(product) }}</span>
        </div>
        <div class="flex">
          <span class="count">款式：{{ handleStyle(product) }}</span>
          <span class="count">尺码：{{ handleSize(product) }}</span>
        </div>
        <div class="flex">
          <span class="count">账号：{{ product.createByAccount }}</span>
          <span class="count"
            >分销商：
            <span v-if="product.mainUser"> {{ product.mainUser }} -</span>
            {{ product.createByName }}
          </span>
        </div>
        <div class="flex">
          <span>定制时间：{{ product.createTime | formatTime }}</span>
        </div>
        <div class="information-warpper">原型信息</div>
        <div class="flex-warpper">
          <div class="left">
            <div class="model">原型：{{ product.name }}</div>
            <defaultImg :src="picture" width="100" height="100" :content="product.chineseName"></defaultImg>
          </div>
          <div class="right">
            <div class="model">原图：</div>
            <defaultImg
              width="100"
              height="100"
              :carouselList="pictureList"
              :content="product.chineseName"
            ></defaultImg>
          </div>
        </div>
        <div class="picture-warpper">
          <div>效果图:</div>
          <el-button
            type="primary"
            style="padding: 0; height: 30px; width: 100px"
            icon="el-icon-download"
            @click="downloadDesignedPicture"
            >批量下载</el-button
          >
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in handleTab" :key="index" :label="item" :name="index.toString()">
          </el-tab-pane>
          <span v-for="item in currentTab" :key="item">
            <defaultImg :size="80" :src="item"  :content="product.chineseName"></defaultImg>
          </span>
        </el-tabs>
        <div class="basic-picture-warpper">
          <div>刀版图:</div>
          <el-button
            type="primary"
            :loading="loading"
            style="padding: 0; height: 30px; width: 100px"
            icon="el-icon-download"
            @click="downloadBasicPicture"
            >批量下载</el-button
          >
        </div>
        <div class="table-warpper">
          <el-table :data="specProds" border ref="watchTableRef">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="自定义sku">
              <template slot-scope="{ row }">
                {{ row.customSku || '暂无' }}
              </template>
            </el-table-column>
            <el-table-column label="外部sku">
              <template slot-scope="{ row }">
                <el-popover v-if="skuLength(row)" placement="top" trigger="hover" :visible-arrow="false">
                  <div slot="reference">
                    <span style="color: #3841db; cursor: pointer">查看外部sku</span>
                  </div>
                  <div style="height: 100px; overflow: auto">
                    <div v-for="(item, index) in handleSku(row)" :key="index">
                      {{ item }}
                    </div>
                  </div>
                </el-popover>
                <div v-else>暂无</div>
              </template>
            </el-table-column>
            <el-table-column prop="basicPicture" label="刀版图">
              <template slot-scope="{ row }">
                <defaultImg
                  :src="getKnifeLayoutPathList(row, true)[1]"
                  :previewSrcList="getKnifeLayoutPathList(row, true)[0]"
                  :carouselList="getKnifeLayoutPathList(row, true)[0]"
                  :errSrc="row.knifeLayoutPath"
                  :noUseCache="true"
                  :size="80"
                  disabled
                ></defaultImg>
              </template>
            </el-table-column>
            <el-table-column prop="isBasicGenerate" label="刀版图是否生成">
              <template slot-scope="{ row }">
                <span>{{ checkGenerate(row) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  :loading="row.loading"
                  v-if="getThumbImageList(row, true).length"
                  @click="downloadImage(getThumbImageList(row, false), row)"
                  >下载</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import defaultImg from '@/views/components/defaultImg'
import { basicListCols as cols } from '../../picture/basePicListPage/module/designedDialog/cols'
import { downloadByZip, downloadImage } from '@/utils/download'
import { createRandomNum } from '@/utils'
import { filter } from 'lodash'
import { aLiCompressPicByUrl } from '@/utils/constant'

export default {
  components: {
    defaultImg
  },

  props: {
    visible: {
      type: Boolean
    },
    product: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      cols,
      activeName: '0',
      currentImageIndex: 0,
      tableData: [
        {
          orderNumber: '',
          way: '',
          expressNumber: '',
          expressTime: '',
          basicState: '',
          importTime: '',
          state: ''
        }
      ]
    }
  },
  computed: {
    skuLength() {
      return (row) => this.handleSku(row)?.length
    },
    pictureList({ originalPicture }) {
      return originalPicture?.map(({ path }) => path)
    },
    specProds() {
      console.log(this.product.customProductList, this.activeName)
      try {
        const { customSpecificProductList } = this.product.customProductList[this.activeName]
        if (!Array.isArray(customSpecificProductList)) {
          return []
        }
        return customSpecificProductList
      } catch (err) {
        return []
      }
    },
    currentImage() {
      try {
        if (!this.originalPicture) return
        return this.originalPicture[this.currentImageIndex].path
      } catch (err) {}
    },
    currentTab() {
      try {
        if (!this.product) return
        return (
          this.product &&
          this.product.customProductList[this.activeName].customShowImageList.map((item) => {
            return item.showImagePath
          })
        )
      } catch (error) {}
    },
    picture() {
      try {
        if (!this.product) return
        return this.product && this.product.customProductList[0].style.styleDisplayImageList[0].displayImagePath
      } catch (error) {}
    },
    originalPicture() {
      try {
        if (!this.product) return
        let picList = []
        this.product.imageList.map((item) => {
          picList.push(item.thumbnailPath)
        })
        picList = picList.map((item, index) => ({
          path: item,
          currentImageIndex: index
        }))
        return picList
      } catch (error) {}
    },
    handleTab() {
      const data = this.product
      return (
        data.customProductList &&
        data.customProductList.map((item) => {
          return item.styleName
        })
      )
    }
  },
  methods: {
    slideChangeHandler(activeIndex) {
      const item = this.originalPicture[activeIndex]
      this.currentImageIndex = activeIndex
      item.currentImageIndex = activeIndex
    },
    async downloadImage(src, data) {
      this.$set(data, 'loading', true)
      if (src.length == 1) {
        await downloadImage(src[0], this.formatName(data))
        return this.$set(data, 'loading', false)
      }
      const paths = src.map((url) => ({ url }))
      await downloadByZip(paths)
      this.$set(data, 'loading', false)
    },
    //使用迅雷下载
    downloadByPath(paths) {
      const tasks = paths.map((url) => {
        return {
          name: '',
          url: `${url}?uid=${createRandomNum()}`
        }
      })
      thunderLink.newTask({
        downloadDir: '', // 指定当前任务的下载目录名称，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。【若不填此项，会下载到用户默认下载目录】
        tasks
      })
    },
    handleSize(data) {
      const size =
        data.customProductList &&
        data.customProductList[0].customSpecificProductList.map((item) => {
          return item.sizeName
        })
      return size && size.join(' ')
    },
    handleCnName(data) {
      return data.name ? data.name : '暂无'
    },
    handleEnName(data) {
      return data.englishName ? data.englishName : '暂无'
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    handleCustomSku(data) {
      const res = data.customSpecificProductList.map((item) => {
        return item.customSku || '暂无'
      })
      return res.join('')
    },
    handleSku(data) {
      const res = data.customSpecificProductSkuMappingList?.map((item) => {
        return item?.externalSku
      })
      return res
    },
    handleStyle(data) {
      const style =
        data.customProductList &&
        data.customProductList.map((item) => {
          return item.styleName
        })
      return style && style.join(' ')
    },
    handleClick(tab) {},
    download(data) {
      let paths = []
      const url = data.path
      if (url) {
        paths.push(url)
      }
      this.downloadByPath(paths) // 修改文件夹名字，填在空字符串内
      return
    },
    downloadDesignedPicture() {
      let paths = []
      this.product.customProductList.map((product) => {
        product.customShowImageList.map((figure) => {
          let path = figure.clearPath || figure.showImagePath
          if (path) {
            paths.push(path)
          }
        })
      })
      this.downloadByPath(paths)
      return
    },
    async downloadBasicPicture() {
      let selections = this.$refs.watchTableRef.selection
      if (!selections.length) {
        this.$message.warning('请先选择刀版图后再进行批量下载操作')
        return
      }
      let paths = []
      selections.map((selection) => {
        selection.knifeLayoutList.map(({ knifeLayoutImagePath }) => {
          if (knifeLayoutImagePath) {
            paths.push(knifeLayoutImagePath)
          }
        })
      })
      if (!paths.filter(Boolean).length) return this.$message.warning('无可下载刀版图')
      this.loading = true
      await this.downloadByPath(paths)
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },

    checkGenerate(data) {
      const list = $GET(data, 'knifeLayoutList', [])
      const len = list.length
      const fLen = filter(list, 'knifeLayoutImagePath').length
      if (!len) return '未生成'
      if (len === fLen) return '已生成'
      return '未全部生成'
    },

    getKnifeLayoutPathList(data) {
      const paths = []
      const list = data?.knifeLayoutList || []
      list.map(({ knifeLayoutImagePath }) => knifeLayoutImagePath && paths.push(knifeLayoutImagePath))
      return [paths, paths.length ? paths[0] : '']
    },

    getThumbImageList(data, isThumb) {
      const paths = []
      const list = data?.knifeLayoutList || []
      list.map(
        ({ knifeLayoutImagePath }) =>
          knifeLayoutImagePath &&
          paths.push(isThumb ? aLiCompressPicByUrl(knifeLayoutImagePath, 500) : knifeLayoutImagePath)
      )
      return paths
    },

    formatName(data) {
      return data?.knifeLayoutList?.map(({ knifeLayoutImagePath }) => {
        const path = knifeLayoutImagePath || ''
        return path.split('/').slice(-1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  ::v-deep {
    .el-carousel__arrow.el-carousel__arrow {
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      height: 36px;
      width: 36px;
      cursor: pointer;
      transition: 0.3s;
      color: #fff;
      position: absolute;
      top: 50%;
      z-index: 10;
      transform: translateY(-50%);
      text-align: center;
      color: #cdcdcd;
      font-weight: 600;
      background: none;
      left: 0;
      font-size: 20px;
    }
    .el-carousel__arrow--left.el-carousel__arrow--left {
      left: 0;
    }
    .el-carousel__arrow--right.el-carousel__arrow--right {
      left: auto;
      right: 0;
    }
  }
  ::v-deep {
    .el-dialog {
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .table-warpper {
    padding: 0px 24px 10px 0;
  }
  .content-warpper {
    height: 700px;
    overflow: auto;
    padding: 22px 0 18px 24px;
    .basic-picture-warpper {
      margin: 40px 24px 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    ::v-deep {
      .el-tabs__nav-wrap {
        margin-right: 24px !important;
      }
    }
    .information-warpper {
      padding-top: 24px;
    }
    .picture-warpper {
      margin: 34px 24px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flex-warpper {
      display: flex;
      ::v-deep {
        .el-popover__reference-wrapper {
          display: inline-block;
          width: 100px;
        }
      }
      .left,
      .right {
        flex: 1;
        .model {
          margin: 18px 0 20px 0;
        }
      }
    }
    .basic-information-warpper {
      margin-bottom: 23px;
    }
    .flex {
      display: flex;
      margin-bottom: 14px;
      .count {
        flex: 1;
      }
    }
  }
  ::v-deep {
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 56px;
      line-height: 56px;
      padding: 0 0 0 22px;
      border: 1px solid #dcdee0;
    }
    .el-dialog__body {
      padding: 0 !important;
      color: #595961;
    }
  }
}
</style>
