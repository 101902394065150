<template>
  <div class="app-container">
    <div class="radioBtn list-state-group">
      <el-radio-group v-model="radioValue">
        <el-radio-button v-for="{ label } in radioGroupBtn" :label="label" :key="label"> </el-radio-button>
      </el-radio-group>
    </div>
    <div v-if="childPermission.search" class="formPart">
      <el-form :model="query" inline size="small">
        <template v-for="{ label, placeholder, prop } in form">
          <!-- 创建时间 / '-->
          <el-form-item v-if="prop == 'createTime'" :key="prop" :label="label">
            <el-date-picker
              v-model="query[`${prop}`]"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchChange"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 定制时间 -->
          <el-form-item v-else-if="prop == 'proTime'" :key="prop" :label="label">
            <el-date-picker
              v-model="query[`${prop}`]"
              type="datetime"
              :placeholder="placeholder"
              @change="searchChange"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 定制产品名称 -->
          <!-- <el-form-item v-else-if="prop == 'chinese_name'" :key="prop" :label="label">
            <el-input v-model="query.name" :placeholder="placeholder" @keyup.native.enter="searchChange"></el-input>
          </el-form-item> -->
          <!-- 图片分类 -->
          <el-form-item v-else-if="prop == 'categoryId'" :key="prop" :label="label">
            <el-cascader
              v-model="query[`${prop}`]"
              :options="category"
              :props="{ ...categoryProps, checkStrictly: true, emitPath: false, expandTrigger: 'hover' }"
              clearable
              @change="searchChange"
            ></el-cascader>
          </el-form-item>
          <!-- 原型分类 -->
          <el-form-item v-else-if="prop == 'platformCategoryId'" :key="prop" :label="label">
            <el-cascader
              v-model="query[`${prop}`]"
              :options="proCategory"
              :props="{ checkStrictly: true, emitPath: false, expandTrigger: 'hover' }"
              clearable
              @change="searchChange"
            ></el-cascader>
          </el-form-item>
          <!-- 档位分类 -->
          <el-form-item v-else-if="prop == 'levelCategoryId'" :key="prop" :label="label">
            <avueCrudSelect v-model="query[`${prop}`]" :dic="gearsCategoryList" @change="searchChange"></avueCrudSelect>
          </el-form-item>
          <!-- 图片上传时间 -->
          <el-form-item v-else-if="prop == 'create_time'" :key="prop" :label="label">
            <el-date-picker
              v-model="query[`${prop}`]"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchChange"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 选择物流方式 -->
          <el-form-item v-else-if="prop == 'expressCompanyId'" :key="prop" :label="label">
            <el-select v-model="query[`${prop}`]" :placeholder="placeholder" clearable @change="searchChange">
              <el-option
                v-for="item in fedexData"
                :key="item[expressCompanyIdProps.value]"
                :label="item[expressCompanyIdProps.label]"
                :value="item[expressCompanyIdProps.value]"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 选择订单状态 -->
          <el-form-item v-else-if="prop == 'orderStatus'" :key="prop" :label="label">
            <el-select v-model="query[`${prop}`]" :placeholder="placeholder" clearable @change="searchChange">
              <el-option v-for="item in orderStatusData" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 选择授权状态 -->
          <el-form-item v-else-if="prop == 'isAuthorized'" :key="prop" :label="label">
            <el-select v-model="query[`${prop}`]" :placeholder="placeholder" clearable @change="searchChange">
              <el-option v-for="item in isAuthorized" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 导入时间 -->
          <el-form-item v-else-if="prop == 'importTime'" :key="prop" :label="label">
            <el-date-picker
              v-model="query[`${prop}`]"
              type="daterange"
              value-format="yyyy-MM-dd"
              :placeholder="placeholder"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="searchChange"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 通用 -->
          <el-form-item v-else-if="prop == 'search'" :key="prop">
            <el-button class="el-icon-search" type="primary" @click="searchChange" size="mini"></el-button>
          </el-form-item>
          <el-form-item v-else :key="prop" :label="label" :prop="prop">
            <el-input
              :placeholder="placeholder"
              clearable
              v-model="query[`${prop}`]"
              @keyup.native.enter="searchChange"
              @clear="searchChange"
            ></el-input>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <!-- 是否是实名认证 -->
    <template v-if="!childPermission.isAuth">
      <paginationOperation class="mt20">
        <template #left>
          <div style="display: inline-block; margin-right: 10px">分销商名称: {{ $route.query.nickName }}</div>
          <checkAllData
            v-if="childPermission.checkAllData && radioValue != '指定图片'"
            class="mr10"
            ref="checkAllData"
            v-bind="checkAllAttrs"
            v-on="checkAllListeners"
            :isHandleRealData="true"
          ></checkAllData>

          <ToggleGroup type="picture" v-if="finalPermission.togglePictureGroup" @successHandler="searchChange" />

          <ToggleGroup v-if="finalPermission.toggleProtoGroup" @successHandler="searchChange" />

          <!-- <loadingBtn
            v-if="finalPermission.assignPrototype"
            type="primary"
            size="small"
            @click="prototypeDialogOpen('prototypes')"
            >分配原型</loadingBtn
          >
          <loadingBtn
            v-if="finalPermission.assignPicture"
            type="primary"
            size="small"
            @click="prototypeDialogOpen('pictures')"
            >分配图片</loadingBtn
          > -->
          <!-- <loadingBtn v-if="finalPermission.batchCancel" type="primary" size="small" @click="onunassign('batch')"
            >批量取消</loadingBtn
          > -->
          <setPrice
            v-if="childPermission.batchSetPrice"
            :isBatch="true"
            class="ml10"
            :value="selectionDataAll"
            :mainAccountUid="id"
            @success="init"
          ></setPrice>
        </template>
        <template #right>
          <loadingBtn v-if="childPermission.exportPriceExcel" size="small" @click="handleExportPriceExcel"
            >导出价格表</loadingBtn
          >
        </template>

        <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
      </paginationOperation>
      <div class="tablePart">
        <el-table
          ref="table"
          key="id"
          :data="data"
          border
          v-loading="tableLoading"
          @selection-change="handleSelectionChange"
        >
          <template v-if="radioValue == '指定原型'">
            <el-table-column type="selection" width="50" align="center" :selectable="selectable"></el-table-column>
          </template>
          <template v-for="{ label, prop, width, hideImg, formatter } in tableColumn">
            <!-- account -->
            <el-table-column
              v-if="prop == 'operation1'"
              :key="prop"
              :label="label"
              :prop="prop"
              align="center"
              width="300"
            >
              <template slot-scope="scope">
                <edit v-if="childPermission.edit" :menus="distors" :data="scope.row" :sup_this="sup_this"></edit>
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'date_joined'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ row.date_joined && row.date_joined.slice(0, 10) }}
              </template>
            </el-table-column>
            <!-- photo -->
            <el-table-column v-else-if="prop == 'title'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                <div class="merge-wrapper">
                  <defaultImg :src="row.thumbnailPath" :content="row.title"></defaultImg>
                  <div style="margin-left: 10px">{{ row.title }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'thumbnailPath'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                <div class="merge-wrapper">
                  <defaultImg :content="row.title" :src="row.thumbnailPath"></defaultImg>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'path'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ row.path && row.path.split('.')[row.path && row.path.split('.').length - 1] }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'create_time'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ row.create_time && row.create_time.slice(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'height'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ row.height + '*' + row.width }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'disable'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ row.disable ? '禁用' : '启用' }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'operation2'" :key="prop" :label="label" :prop="prop" align="center">
              <template slot-scope="{ row }">
                <disabledBtn v-if="childPermission.disable" :data="row" :sup_this="sup_this"> </disabledBtn>
              </template>
            </el-table-column>
            <!-- order -->
            <el-table-column v-else-if="prop == 'expressShippingMethodCnName'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ row.expressShippingMethodCnName || row.expressCompanyName || '暂无' }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'isKnifeLayoutCreated'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ row.isKnifeLayoutCreated == 0 ? '未全部生成' : '已全部生成' }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'orderStatus'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ ORDER_STATUS_LIST[`${row.orderStatus}`] }}
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="prop == 'operation3'"
              :key="prop"
              :label="label"
              :prop="prop"
              align="center"
              width="300"
            >
              <template slot-scope="scoped">
                <changeStateBtn v-if="childPermission.update" :data="scoped.row" :sup_this="sup_this"></changeStateBtn>
                <editOrderBtn
                  v-if="canEdit(scoped.row) && childPermission.edit"
                  :data="scoped.row"
                  :type="1"
                  :id="scoped.row.id"
                  :sup_this="sup_this"
                >
                  编辑
                </editOrderBtn>
                <editOrderBtn v-if="childPermission.detail" :data="scoped.row" :isProTable="true" :id="scoped.row.id">
                  查看详情
                </editOrderBtn>
              </template>
            </el-table-column>
            <!-- pro -->
            <el-table-column v-else-if="prop == 'chinese_name'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ row.name || '暂无' }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'english_name'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                {{ row.english_name || '暂无' }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'images_path'" :key="prop" :label="label" :prop="prop">
              <template slot-scope="{ row }">
                <defaultImg :content="row.chineseName" :carouselList="originalImageList(row)"></defaultImg>
                <!-- <div class="merge-wrapper">
                  <el-popover placement="right" trigger="hover" :visible-arrow="false">
                    <div slot="reference">
                      <el-image
                        fit="contain"
                        :src="row.imageList && row.imageList[0] && row.imageList[0].thumbnailPath"
                        :z-index="9999"
                      />
                    </div>
                    <div style="height: 40px; line-height: 32px; background: #fff">
                      {{ row.chineseName }}
                    </div>
                    <el-image
                      fit="contain"
                      :src="row.imageList && row.imageList[0] && row.imageList[0].thumbnailPath"
                      style="width: 450px; height: 450px"
                      :z-index="9999"
                    >
                    </el-image>
                  </el-popover>
                </div> -->
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'primName'" :key="prop" :label="label" :prop="prop">
              <template v-slot="{ row }">
                {{ row.name }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'styleName'" :key="prop" :label="label" :prop="prop">
              <template v-slot="{ row }">
                <el-tag style="margin-right: 5px" type="primary" :key="index" v-for="(item, index) in handleStyle(row)">
                  {{ item }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'sizeName'" :key="prop" :label="label" :prop="prop">
              <template v-slot="{ row }">
                <el-tag style="margin-right: 5px" type="primary" :key="id" v-for="{ sizeName, id } in handleSize(row)">
                  {{ sizeName }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'spec_prods_path'" :key="prop" :label="label" :prop="prop">
              <template #default="{ row }">
                {{ handleState(row) }}
              </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'create_time1'" :key="prop" :label="label" :prop="prop">
              <template #default="{ row }">
                <span>{{ row.create_time | formatTime }}</span>
              </template>
            </el-table-column>

            <el-table-column v-else-if="prop == 'operation4'" :key="prop" :label="label" :prop="prop" align="center">
              <!-- <template #default="{ row }">
              <proDetail :detailData="row"></proDetail>
            </template> -->
              <template slot-scope="{ row }">
                <ColorTextBtn v-if="childPermission.detail" class="watch" @click="onWatch(row)">查看详情</ColorTextBtn>
              </template>
            </el-table-column>

            <el-table-column v-else-if="prop == 'shopName'" :key="prop" :label="label" :prop="prop" :width="width">
              <template #default="{ row }">
                <div style="position: relative">
                  <InfoPart label="名称" :text="row.shopName" />
                  <InfoPart v-if="row.shopType == 2" label="站点" :text="getWebSite(row.websiteAreaCode)" />
                  <!-- <StatusTag type="danger" v-if="row.isEnabled == 0"> 已禁用 </StatusTag> -->
                </div>
              </template>
            </el-table-column>

            <el-table-column v-else-if="prop == 'tagStatus'" :key="prop" :label="label" :prop="prop" :width="width">
              <template #default="{ row }">
                <div style="position: relative" v-if="row.isEnabled == 0">
                  <StatusTag type="danger"> 已禁用 </StatusTag>
                </div>
              </template>
            </el-table-column>

            <el-table-column v-else-if="prop == 'isAutoSyncOrder'" :key="prop" :label="label" :prop="prop">
              <template #default="{ row }"> {{ row.isAutoSyncOrder == 1 ? '是' : '否' }} </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'isAutoSyncWaybill'" :key="prop" :label="label" :prop="prop">
              <template #default="{ row }"> {{ row.isAutoSyncWaybill == 1 ? '是' : '否' }} </template>
            </el-table-column>
            <el-table-column v-else-if="prop == 'isAuthorized'" :key="prop" :label="label" :prop="prop">
              <template #default="{ row }"> {{ row.isAuthorized == 1 ? '已授权' : '未授权' }} </template>
            </el-table-column>

            <el-table-column v-else-if="prop == 'operation5'" :key="prop" :label="label" :prop="prop" align="center">
              <template slot-scope="{ row }">
                <EditShop
                  v-if="childPermission.hasEidt && row.isEnabled == 1"
                  :initData="row"
                  :sup_this="sup_this"
                ></EditShop>
                <el-divider v-if="childPermission.hasEidt && row.isEnabled == 1" direction="vertical"></el-divider>
                <AbleOperateStore
                  v-if="row.isEnabled == 0 && childPermission.hasEnable"
                  :initData="row"
                  :sup_this="sup_this"
                  title="启用"
                ></AbleOperateStore>
                <AbleOperateStore
                  v-if="row.isEnabled !== 0 && childPermission.hasDisable"
                  title="禁用"
                  :initData="row"
                  :sup_this="sup_this"
                ></AbleOperateStore>
              </template>
            </el-table-column>

            <el-table-column v-else-if="prop == 'shopType'" :key="prop" :label="label" :prop="prop">
              <template #default="{ row }"> {{ platFormsDic[row[prop]] }} </template>
            </el-table-column>

            <el-table-column v-else-if="prop == 'priceSlot'" :key="prop" :label="label" :prop="prop">
              <template #default="{ row: scoped }">
                <div class="flex-column">
                  <template v-if="childPermission.viewPrice && hasPrice(scoped)">
                    <div :class="{ 'text-primary': scoped.hasFixedLevelCategory }">
                      ￥{{ minPrice(scoped) }} - ￥{{ maxPrice(scoped) }}
                    </div>
                    <viewPriceDialog
                      :data="scoped"
                      :mainAccountUid="id"
                      :memberLevelId="memberLevelId"
                      :showFixedLevel="true"
                    ></viewPriceDialog>
                  </template>
                  <div v-else>暂无数据</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              v-else-if="prop === 'assignOperation'"
              :key="prop"
              :label="label"
              :prop="prop"
              align="center"
            >
              <template slot-scope="{ row }">
                <template v-if="childPermission.setPrice">
                  <setPrice
                    v-if="selectable(row)"
                    class="menu-btn-item"
                    :value="row"
                    :mainAccountUid="id"
                    @success="init"
                  ></setPrice>
                  <span v-else class="cursor-pointer text-info text-mini">价格设置</span>
                </template>
                <!--<loadingBtn
                    v-if="finalPermission.unassign"
                    type="text"
                    size="mini"
                    class="watch menu-btn-item"
                    @click="onunassign(row)"
                  >取消分配</loadingBtn
                >-->
              </template>
            </el-table-column>
            <!-- 通用 -->
            <el-table-column v-else :key="prop" :label="label" :prop="prop" :formatter="formatter"></el-table-column>
          </template>
        </el-table>
      </div>
      <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    </template>
    <AuthenticationByRealName v-if="childPermission.isAuth" :data="AuthenData" />
    <watchDialog :visible.sync="show" :product="designedProduct" />
    <prototypeDialog
      v-if="childPermission.assignPrototype || childPermission.assignPicture"
      ref="prototypeDialog"
      :resetMergeData="prototypeDialogMergeData"
    ></prototypeDialog>
  </div>
</template>

<script>
import watchDialog from '../../designedProductManage/module/watchDialog.vue'
import { getDetail, exportPriceExcel } from './resellerAPI'
import {
  WAIT_RELATION,
  WAIT_GENERATE_WAYBILL,
  WAIT_DISPATCH,
  IN_DISPATCH,
  DELIVER,
  FINISH,
  PROTOTYPE_APPOINT,
  PICTURE_APPOINT
} from '@/utils/constant'
import { radioGroupBtn, orderStatusData } from './resellerPageData'
// expressExpireTime
import { cloneDeep } from 'lodash'
import { parseCharacterTime } from '@/utils'
import changeStateBtn from './module/changeStateBtn.vue'
import initDataMixin from '@/mixins/initData'
import checkAllMixin from '@/views/components/checkAllData3/checkAllMixin'
import disabledBtn from './module/disabledBtn.vue'
import edit from '../module/edit.vue'
import editOrderBtn from './module/edit'
import proDetail from './module/proDetail.vue'
import AuthenticationByRealName from './module/authenticationByRealName'
import prototypeDialog from '@/views/user/module/prototypeDialog'
import { PIC_ENABLE, SHELVES, ORDER_STATUS_LIST } from '@/utils/constant'
import { mapGetters, mapActions } from 'vuex'

//更换图片分组
import ToggleGroup from '@/views/components/toggleGroup'

import EditShop from '../store/manage/module/editShop/index.vue'
import AbleOperateStore from '../store/manage/module/ableOperateStore/index.vue'
import InfoPart from '../store/manage/module/baseStoreComponent/module/infoPart'
import StatusTag from '../store/manage/module/baseStoreComponent/module/statusTag'
import viewPriceDialog from '@/views/product/baseProductPage/module/viewPriceDialog'
import setPrice from './module/setPrice'
import paginationOperation from '@/views/components/base/paginationOperation'
import saveAs from 'jszip/vendor/FileSaver.js'
import { validatenull } from '@/components/avue/utils/validate'
import { handleFixedLevelCategoryByLevelItemId } from '@/views/product/utils'
import defaultImg from '@/views/components/defaultImg'

export default {
  mixins: [
    initDataMixin,
    checkAllMixin({
      type: 'table'
    })
  ],
  components: {
    defaultImg,
    paginationOperation,
    disabledBtn,
    edit,
    changeStateBtn,
    editOrderBtn,
    proDetail,
    watchDialog,
    prototypeDialog,
    EditShop,
    AbleOperateStore,
    InfoPart,
    StatusTag,
    AuthenticationByRealName,
    viewPriceDialog,
    setPrice,

    ToggleGroup
  },
  data() {
    return {
      ORDER_STATUS_LIST,
      id: Number(this.$route.query.id),
      memberLevelId: Number(this.$route.query.memberLevelId),
      sup_this: this,
      distors: [],
      radioValue: '子账号管理',
      accountTableData: [],
      proTableData: [],
      phoTableData: [],
      orderStatusData,
      orderTableData: [],
      loading: true,
      method: 'post',
      initBool: false,
      show: false,
      AuthenData: {},
      designedProduct: {},
      orderItems: [
        {
          asc: false,
          column: 'order_time'
        },
        {
          asc: false,
          column: 'id'
        }
      ],

      prototypeDialogType: ''
    }
  },

  watch: {
    radioGroupBtn: {
      handler(n) {
        if (n.length) {
          this.radioValue = n[0].label
        }
      },
      immediate: true
    },
    radioValue: {
      handler(n) {
        this.getTableData()
        //在beforeInit之前，初始化this.query
        this.beforeInitCallback()
        this.searchChange()
      },
      immediate: true
    }
  },
  created() {
    Promise.all([this.GetPlatforms(), this.GetWebsiteAreaCodeDict()])
  },
  computed: {
    ...mapGetters([
      'category',
      'categoryProps',
      'proCategory',
      'fedexData',
      'expressCompanyIdProps',
      'expressShippingMethodId',
      'expressShippingMethodIdProps',
      'gearsCategory',
      'shop',
      'isAuthorized'
    ]),
    // 原图
    originalImageList() {
      return (product) => {
        console.log('originalImageList', product)
        try {
          if (!product) return
          let picList = []
          product.imageList.map((item) => {
            picList.push(item.thumbnailPath)
          })
          picList = picList.map((item, index) => item)
          return picList
        } catch (error) {}
      }
    },
    gearsCategoryList({ gearsCategory }) {
      return [
        {
          label: '全部档位',
          value: 'all'
        },
        ...gearsCategory
      ]
    },
    calcWebsiteAreaSiteDic() {
      return this.shop.websites
    },
    platFormsDic() {
      let obj = {}
      this.shop.platforms.map((item) => {
        obj[item.code] = item.name
      })
      return obj
    },
    radioGroupBtn() {
      return radioGroupBtn
    },
    curItem({ radioGroupBtn, radioValue }) {
      return radioGroupBtn.find(({ label }) => label === radioValue) || {}
    },
    childPermission({ curItem: { childPermission } }) {
      return childPermission
    },
    finalPermission({ childPermission }) {
      return {
        ...childPermission,
        assignPrototype: childPermission.assignPrototype && this.canAssign,
        assignPicture: childPermission.assignPicture && this.canAssign,
        batchCancel: childPermission.batchCancel && this.canAssign,
        unassign: childPermission.unassign && this.canAssign,
        togglePictureGroup: childPermission.togglePictureGroup && this.canAssign,
        toggleProtoGroup: childPermission.toggleProtoGroup && this.canAssign
      }
    },
    form() {
      return this.curItem.formOption
    },
    tableColumn() {
      return this.curItem.tableColumn
    },
    url() {
      return this.curItem.url
    },
    handleSearchFormProps({ form }) {
      let tempObj = {}
      let timeProps = []
      form.forEach(({ prop }) => {
        if (this.getTimeProps(prop)) {
          timeProps.push(prop)
        }
      })
      timeProps.forEach((key) => {
        tempObj[key] = (val) => {
          return {
            $isMergeData: true,
            ...this.handleSearchTime(key, val)
          }
        }
      })
      return tempObj
    },
    canEdit() {
      return (row) => {
        const { orderStatus } = row
        const temArr = [WAIT_RELATION, WAIT_GENERATE_WAYBILL, WAIT_DISPATCH, IN_DISPATCH]
        return true
      }
    },
    hasMenuLeft() {
      return
    },
    canAssign({ radioValue }) {
      if (radioValue === '指定图片') return Number(this.$route.query.imagePermissionType) === PICTURE_APPOINT
      return Number(this.$route.query.prototypePermissionType) === PROTOTYPE_APPOINT
    },
    prototypeDialogMergeData({ prototypeDialogType }) {
      if (prototypeDialogType === 'pictures') {
        return {
          // notDistributorUserId: this.id,
          // 已上架图片
          disable: PIC_ENABLE
        }
      }
      return {
        // notDistributorUserId: this.id,
        // 已上架原型
        status: SHELVES
      }
    },
    hasPrice() {
      return ({ sizeList }) => {
        return (sizeList || []).some(({ sizeLevelCategoryPriceList }) => {
          return (
            Array.isArray(sizeLevelCategoryPriceList) &&
            sizeLevelCategoryPriceList.some(({ price }) => price || price === 0)
          )
        })
      }
    },
    minPrice() {
      return (data) => {
        const { sizeList } = data
        let priceArr = []
        ;(sizeList || []).map((size) => {
          const { sizeLevelCategoryPriceList, fixedLevelCategory } = size
          if (fixedLevelCategory) {
            return priceArr.push(Number(fixedLevelCategory.price))
          }
          ;(sizeLevelCategoryPriceList || []).map((item) => {
            priceArr.push(Number(item.price))
          })
        })
        let min = priceArr[0]
        for (let i = 1; i < priceArr.length; i++) {
          if (priceArr[i] < min) {
            min = priceArr[i]
          }
        }
        return min
      }
    },
    maxPrice() {
      return (data) => {
        const { sizeList } = data
        let priceArr = []
        ;(sizeList || []).map((size) => {
          const { sizeLevelCategoryPriceList, fixedLevelCategory } = size
          if (fixedLevelCategory) {
            return priceArr.push(Number(fixedLevelCategory.price))
          }
          ;(sizeLevelCategoryPriceList || []).map((item) => {
            priceArr.push(Number(item.price))
          })
        })
        let max = priceArr[0]
        for (let i = 1; i < priceArr.length; i++) {
          if (priceArr[i] > max) {
            max = priceArr[i]
          }
        }
        return max
      }
    }
  },

  methods: {
    ...mapActions(['GetPlatforms', 'GetWebsiteAreaCodeDict']),
    testEnter() {},
    beforeInitCallback() {
      const { id, radioValue } = this
      let query = {
        [['指定原型', '指定图片'].includes(radioValue) ? 'distributorUserId' : 'mainAccountUid']: id
      }
      switch (radioValue) {
        case '分销商订单管理':
          query.orderItems = this.orderItems
          query.isReturnRelatedData = 1
          query.isGetAllOrder = 1
          break
        case '指定原型':
          query.status = SHELVES
          query.orderItems = [
            { asc: false, column: 'create_time' },
            { asc: true, column: 'id' }
          ]
          break
        case '指定图片':
          query.disable = PIC_ENABLE
          break
        case '实名认证':
          query.id = this.$route.query.id
          break
      }
      // console.log(this.radioValue, JSON.stringify(query))
      this.mergeData = query
    },
    handleTableData(data) {
      data = this.curItem.handleTableData?.(data) || data
      return data.map((row) => {
        row.hasFixedLevelCategory = handleFixedLevelCategoryByLevelItemId(row.sizeList, row.fixedLevelCategoryConfigId)
        return row
      })
    },
    initCallBack(res, postData) {
      this.curItem.initCallBack?.call(this)
      if (this.radioValue === '实名认证') {
        this.AuthenData = res.detail || {}
      }
      this.checkAllInitCallBack(res, postData)
    },
    getTableData() {
      this.query = {}
      let { query } = this
      this.form.forEach((item) => {
        this.$set(query, item.prop, '')
      })
      console.log(this.query)
    },
    handleStyle(data) {
      if (Array.isArray(data.customProductList)) {
        return data.customProductList
          .map((item) => {
            return item.styleName
          })
          .filter((d) => d)
      }
      return []
    },
    handleSize(data) {
      if (Array.isArray(data.customProductList)) {
        return data.customProductList[0].customSpecificProductList
          .map((item) => {
            return item
          })
          .filter(({ sizeName }) => sizeName)
      }
      return []
    },
    handleState(data) {
      let generate = true
      data.customProductList?.map(({ customSpecificProductList }) => {
        customSpecificProductList = customSpecificProductList || []
        return customSpecificProductList.map(({ knifeLayoutList }) => {
          if (!knifeLayoutList) return (generate = false)
          knifeLayoutList.every(({ knifeLayoutImagePath }) => {
            if (!knifeLayoutImagePath) return (generate = false)
          })
        })
      })
      return generate ? '已全部生成' : '未全部生成'
    },
    async onWatch(data) {
      const { id } = data
      await getDetail({ id }).then(({ detail }) => {
        this.designedProduct = detail
      })
      this.show = true
    },
    async onunassign(row) {
      let selectionData = await this.getSelectionData(row)
      // console.log(selectionData)
      if (!selectionData) return

      let name = this.curItem.label.replace('指定', '')
      let title = '批量取消'
      let message = `确认是否取消分配的${name}`
      if (row !== 'batch') {
        title = '温馨提示'
        message = `确认是否取消${row.title}${name}的分配`
      }
      let action = await awaitFormResolve(this.$confirm(message, title))
      // console.log(action)
      if (!action) return

      let res = await awaitResolve(
        this.curItem.unassignApi({
          value: selectionData.map(({ id }) => id),
          userId: this.id
        })
      )
      // console.log(res)
      if (!res) return
      this.$message.success('操作成功')
      this.refreshPage()
    },
    async handleExportPriceExcel() {
      let { selectionDataAll } = this
      if (validatenull(selectionDataAll)) {
        return this.$message.warning('请至少选择一条数据')
      }
      let res = await awaitFormResolve(
        exportPriceExcel({
          mainAccountUid: this.id,
          prototypeIdList: selectionDataAll.map(({ id }) => id)
        })
      )
      if (res) {
        saveAs(res, 'zdcustom产品价格表')
        this.$message.success('导出成功')
      } else {
        this.$message.warning('无数据可导出')
      }
    },
    async prototypeDialogOpen(type) {
      this.prototypeDialogType = type
      // 防止 prototypeDialog 获取旧的 prototypeDialogMergeData
      await Promise.resolve()
      let value = await awaitFormResolve(this.$refs.prototypeDialog.dialogOpen([], type))
      // console.log(value)
      if (!value) return

      let res = await awaitResolve(
        this.curItem.assignApi({
          value,
          mainAccountUidList: [this.id]
        })
      )
      // console.log(res)
      if (!res) return

      this.$message.success('操作成功')
      this.refreshPage()
    },
    getWebSite(val) {
      try {
        let result = JSON.parse(val)
        let codes = result?.countryIdList
        let countryNames = []
        codes.forEach((code) => {
          countryNames.push(
            this.calcWebsiteAreaSiteDic.find((item) => {
              return item.id == code
            })?.countryCnName
          )
        })
        return countryNames.join(' ')
      } catch (error) {
        return '暂无'
      }
    },

    handleCheckData(data) {
      return this.curItem.handleTableData?.(data) || data
    },
    selectable(row) {
      return row.status === SHELVES
    }
  }
}
</script>

<style scoped lang="scss">
.formPart {
  margin-top: 25px;
  padding: 20px;
  background: #f7f8fa;
  .el-form-item {
    margin-right: 15px;
  }
}
.merge-wrapper {
  display: flex;
  align-items: center;
  .el-image {
    flex: 0 0 44px;
    width: 44px;
    height: 44px;
    background: $bg-color;
  }
  .name {
    margin-left: 10px;
    flex: 1;
  }
}
::v-deep {
  .tablePart .cell {
    overflow: inherit;
  }
  .tablePart tr td:first-child {
    border-right: none;
  }
}
</style>
