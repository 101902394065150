<script>
import dialog from '@/extend/components/dialog/index'
import setPrice from './module/setPrice'
import batchSetPrice from './module/batchSetPrice'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  extends: dialog,
  props: {
    isBatch: Boolean,
    value: {
      required: true
    },
    mainAccountUid: {
      required: true
    }
  },
  computed: {
    componentName({ isBatch }) {
      return isBatch ? batchSetPrice : setPrice
    },
    dialogAttrs({ isBatch }) {
      return {
        title: isBatch ? '批量设置价格' : `${this.value.chineseName} - 价格设置`
      }
    },
    btnAttrs({ isBatch }) {
      if (isBatch) {
        return {
          size: 'small',
          type: 'primary',
          text: '批量设置价格'
        }
      }

      return {
        size: 'mini',
        type: 'text',
        text: '价格设置'
      }
    }
  },
  methods: {
    onclick() {
      if (this.isBatch && validatenull(this.value)) {
        return this.$message.warning('请至少选择一条数据')
      }

      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.extend-dialog-btn .el-button.el-button--mini {
  font-size: 12px;
}
</style>
