<template>
  <el-dialog
    v-el-drag-dialog
    :title="`选择${curItem.title}`"
    :visible.sync="dialogVisible"
    top="5vh"
    width="888px"
    custom-class="prototype-dialog dialog-header-border"
    append-to-body
    @opened="getElTableInstance"
    @closed="initCheckAllData"
  >
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="tableOption"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
      @select="handleSelect"
      @select-all="handleSelectAll"
    >
      <template #menuLeft>
        <el-checkbox
          v-model="checkedAll"
          :indeterminate="indeterminate"
          :disabled="checkedAllDisabled"
          class="checkbox-large"
          @change="checkedAllChange"
        >全选</el-checkbox>
      </template>
      <template #imgText="{ row }">
        <div class="flex-middle">
          <defaultImg :src="row.$displayImagePath" style="width: 50px; height: 50px;"></defaultImg>
          <div class="ml10">{{ row.name }}</div>
        </div>
      </template>
      <template #thumbnailPath="{ row }">
        <div class="flex-center-middle">
          <defaultImg :src="row.thumbnailPath" style="width: 50px; height: 50px;"></defaultImg>
        </div>
      </template>
    </avue-crud>
    <el-divider></el-divider>
    <!-- 选择的原型列表 -->
    <div class="mt10">
      已选择{{ curItem.title }}
      <color-text-btn @click="clearSelection">取消全部{{ selectionDataAll.length }}</color-text-btn>
    </div>
    <InfiniteScroll
      v-show="hasSelectionDataAll"
      class="checkbox-img"
      :span="4"
      :isShowLoading="false"
      v-loading="selectionDataAllLoading"
      :data="infiniteScrollData"
      :total="selectionDataAll.length"
      @load="load"
    >
      <template v-slot="{ data }">
        <el-checkbox class="checkbox-img-item" :checked="true" @change="toggleRowSelection(data)">
          <div class="img-wrapper mb10">
            <defaultImg v-if="getDisplayImagePath(data)" :src="getDisplayImagePath(data)"></defaultImg>
            <el-tooltip :content="data[nameProp]" effect="dark" placement="bottom">
              <div class="img-text text-cut">{{ data[nameProp] }}</div>
            </el-tooltip>
          </div>
        </el-checkbox>
      </template>
    </InfiniteScroll>
    <template #footer>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="selectionDataAllLoading" @click="onsubmit">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { avueCrud, dialogComponentsMixin, checkAllDataMixin } from '@/mixins'
import { prototypeDialogTableOption, pictureDialogTableOption } from './const'
import { setDefault } from '@/components/avue/utils/util'
import { getAllData, getOpenList } from '@/views/product/maintain/basicsMange/module/api'
import InfiniteScroll from '@/components/infiniteScroll'

let options = {
  pictures: {
    title: '图片',
    nameProp: 'title',
    tableOption: pictureDialogTableOption,
    getListFn: getOpenList,
    getDisplayImagePath(item) {
      return item.thumbnailPath
    }
  },
  default: {
    title: '原型',
    nameProp: 'name',
    tableOption: prototypeDialogTableOption,
    getListFn: getAllData,
    getDisplayImagePath(item) {
      return item.styleList?.[0]?.styleDisplayImageList?.[0]?.displayImagePath
    }
  }
}

export default {
  mixins: [
    dialogComponentsMixin,
    avueCrud(),
    // 放在avueCrud之后，否则checkAllDataMixin的afterInit会被覆盖
    checkAllDataMixin()
  ],
  components: {
    InfiniteScroll
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'default'
    },
    resetMergeData: Object
  },
  data() {
    return {
      curType: '',
      tablePage: {
        pageIndex: 1,
        pageSize: 5,
        total: 0
      },
      infiniteScrollPage: {
        pageIndex: 1,
        pageSize: 12
      }
    }
  },
  computed: {
    curItem() {
      return options[this.curType] || options.default
    },
    getListFn() {
      return this.curItem.getListFn
    },
    getDisplayImagePath() {
      return this.curItem.getDisplayImagePath
    },
    nameProp() {
      return this.curItem.nameProp
    },
    infiniteScrollData({ selectionDataAll, infiniteScrollPage: { pageIndex, pageSize } }) {
      // console.log(selectionDataAll, pageIndex)
      return selectionDataAll.slice(0, pageIndex * pageSize)
    }
  },
  watch: {
    curItem: {
      handler(n) {
        let { tableOption } = n
        tableOption && this.$store.dispatch('HandleOption', tableOption)
        this.tableOption = tableOption
      },
      deep: true
    },
    selectionDataAll: {
      handler(n) {
        let { infiniteScrollPage: { pageIndex, pageSize }, infiniteScrollPage } = this
        let maxPage = Math.ceil(n.length / pageSize)
        // pageIndex最小为1，否则load不生效
        if (pageIndex > maxPage) infiniteScrollPage.pageIndex = Math.max(maxPage, 1)
      },
      deep: true
    },
    // 处理在全选并点击确定之后，全部数据未返回却打开弹窗时，选择数据为空的问题
    value: {
      handler(n) {
        if (this.dialogVisible) {
          this.setSelection(n)
        }
      },
      deep: true
    },
    dialogVisible: {
      handler(n) {
        if (!n) {
          this.dialogClose('close')
        }
      },
      immediate: true
    }
  },
  methods: {
    async onsubmit() {
      // 防止在全选之后，立即点击确定，导致this.selectionDataAll数据缺少
      let selectionDataAll = await this.getSelectionDataAllArr()
      console.log(selectionDataAll)
      if (!selectionDataAll.length) {
        return this.$message.warning('请先选择数据')
      }
      
      this.dialogReturnType = 'success'
      this.dialogVisible = false
      let value = selectionDataAll.map(row => row.id)
      console.log(value)
      this.$emit('input', value)
      this.dialogClose(value)
    },
    
    handleTableData(data) {
      setDefault(data, ['styleList', 'sizeList'])
      return data.map(item => {
        item.$displayImagePath = this.getDisplayImagePath(item)
        item.$styleName = item.styleList.map(style => style.name).join('、')
        item.$sizeName = item.sizeList.map(style => style.sizeName).join('、')
        return item
      })
    },
    load() {
      this.infiniteScrollPage.pageIndex++
    },
  
    async dialogOpen(value = this.value, type = this.type) {
      this.dialogReturnType = ''
      this.curType = type
      this.dialogVisible = true
      this.setSelection(value)
      
      this.searchForm = {}
      this.$nextTick(function () {
        this.$refs.crud?.formInit()
      })
      
      this.searchChange()
      return new Promise((resolve, reject) => {
        this.dialogResolveFn = resolve
        this.dialogRejectFn = reject
      })
    },
    dialogClose(value) {
      let fnType = value === 'close' ? 'dialogReject' : 'dialogResolve'
      if (this.dialogReturnType === 'success' && fnType === 'dialogReject') return
      return this[fnType](value)
    },
    dialogResolve(value) {
      return typeof this.dialogResolveFn === 'function' && this.dialogResolveFn(value)
    },
    dialogReject(value) {
      return typeof this.dialogRejectFn === 'function' && this.dialogRejectFn(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.prototype-dialog {
  .formPart {
    margin: 0;
  }
  .checkbox-img {
    min-height: 100px;
    max-height: 300px;
    .checkbox-img-item {
      width: 100%;
    
      .img-wrapper {
        height: 0;
        padding-top: calc(100% + 20px);
      
        .el-image {
          position: absolute;
          top: 0;
          left: 0;
          height: calc(100% - 20px);
        }
      
        .img-text {
          position: absolute;
          left: 8px;
          right: 8px;
          bottom: 0;
        }
      }
    }
  }
}

::v-deep {
  .checkbox-img {
    .checkbox-img-item {
      position: relative;
      width: 258px;
      
      &:hover {
        .img-mask {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(209, 209, 209, 0.51);
        }
      }
      .img-mask {
        display: none;
        cursor: zoom-in;
        
        .el-button {
          position: absolute;
          
          &.collect-btn {
            top: 10px;
            right: 10px;
            padding: 0;
            font-size: 26px;
            color: $color-primary;
          }
          &.design-btn {
            left: 10px;
            bottom: 10px;
            padding: 8px 24px;
            background-color: rgba(84, 84, 84, 0.71);
          }
        }
      }
      
      .img-wrapper {
        position: relative;
        height: 200px;
        border: 1px solid #dcdee0;
        border-radius: 5px;
        text-align: center;
        overflow: hidden;
        
        .el-image {
          width: 100%;
          height: 100%;
          
          div,
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .img-name {
        margin-top: 5px;
        font-weight: 700;
        font-size: 14px;
        color: #333333;
      }
      
      .el-checkbox__input,
      .el-dropdown {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      .el-dropdown {
        left: auto;
        right: 10px;
      }
      .el-button {
        padding: 5px 7px;
      }
      
      .el-checkbox__label {
        width: 100%;
        padding-left: 0;
        color: #606266;
      }
    }
    
    .checkbox-img-col {
      margin-bottom: 15px;
    }
  }
}
</style>
