<template>
  <!-- 字段信息 -->
  <div class="info-part-component">
    <span class="lable">{{ label }}：</span>
    <span class="text">{{ text || '暂无' }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: true
    },

    text: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.info-part-component {
  font-size: 14px;
  line-height: 19px;
  .lable {
    color: #9da3ac;
  }
  .text {
    color: #495060;
  }
}
</style>