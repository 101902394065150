/* eslint-disable */
import { checkPermission, getValueFromObj } from '@/utils'
import {
  userDistributorImageBulkDelete,
  userDistributorImageDataSet,
  userDistributorPrototypeBulkDelete,
  userDistributorPrototypeSet
} from '@/api/auth/distributorAssign'
// account
export const accountForm = [
  { label: '账号名称', placeholder: '输入子账号名称', prop: 'nickName' },
  { label: '创建时间', placeholder: '请选择时间', prop: 'createTime' },
  { label: '账号', placeholder: '输入账号', prop: 'accountName' },
  { prop: 'search' }
]
export const accountTable = [
  { label: '账号名称', prop: 'nickName' },
  { label: '创建时间', prop: 'createTime' },
  { label: '账号', prop: 'accountName' },
  { label: '操作', prop: 'operation1' }
]
export const accountFormData = {
  nick_name: '',
  username: '',
  date_joined: ''
}
// pro
export const proForm = [
  { label: '定制产品名称', placeholder: '输入定制产品名称', prop: 'name' },
  { label: '原型名称', placeholder: '输入原型名称', prop: 'prototypeName' },
  { label: '定制时间', placeholder: '请选择时间', prop: 'create_time' },
  { label: '款式', placeholder: '输入款式', prop: 'styleName' },
  { prop: 'search' }
]
export const proFormData = {
  prototypeName: '',
  name: '',
  createTime: '',
  styleName: ''
}
export const proTable = [
  { label: '定制产品名称', prop: 'name' },
  { label: '定制产品英文名', prop: 'englishName' },
  { label: '原型名称', prop: 'primName' },
  { label: '定制原图', prop: 'images_path' },
  { label: '款式', prop: 'styleName' },
  { label: '尺码', prop: 'sizeName' },
  { label: '分销商', prop: 'createByName' },
  { label: '刀版图状态', prop: 'spec_prods_path' },
  { label: '定制时间', prop: 'createTime' },
  { label: '操作', prop: 'operation4' }
]
//photo
export const photoForm = [
  { label: '图片名称', placeholder: '输入图片名称', prop: 'title' },
  { label: '图片分类', placeholder: '请选择分类', prop: 'categoryId' },
  { label: '上传时间', placeholder: '请选择时间', prop: 'create_time' },
  { prop: 'search' }
]

export const photoFormData = {
  category_name: '',
  title: '',
  create_time: ''
}

export const phoTable = [
  { label: '图片名称', prop: 'title' },
  { label: '图片分类', prop: 'categoryName' },
  { label: '图片', prop: 'thumbnailPath' },
  { label: '格式', prop: 'path' },
  { label: '尺寸', prop: 'height' },
  { label: '上传时间', prop: 'createTime' },
  { label: '状态', prop: 'disable' },
  { label: '操作', prop: 'operation2' }
]

// order
export const orderForm = [
  { label: '订单号', placeholder: '输入订单号', prop: 'orderCode' },
  { label: '运单号', placeholder: '输入运单号', prop: 'expressWaybillCode' },
  { label: '物流方式', placeholder: '选择物流方式', prop: 'expressCompanyId' },
  { label: '订单状态', placeholder: '选择订单状态', prop: 'orderStatus' },
  { label: '导入时间', placeholder: '请选择时间', prop: 'importTime' },
  { prop: 'search' }
]
export const orderFormData = {
  orderCode: '',
  expressWaybillCode: '',
  expressCompanyId: '',
  orderStatus: '',
  importTime: ''
}

export const orderTable = [
  { label: '订单号', prop: 'orderCode' },
  { label: '物流方式', prop: 'expressShippingMethodCnName' },
  { label: '运单号', prop: 'expressWaybillCode' },
  { label: '物流到期时间', prop: 'expressExpireTime' },
  { label: '刀版图状态', prop: 'isKnifeLayoutCreated' },
  { label: '导入时间', prop: 'orderTime' },
  { label: '订单状态', prop: 'orderStatus' },
  { label: '操作', prop: 'operation3' }
]
export const orderStatusData = [
  { label: '待关联', value: 11 },
  { label: '待生成运单', value: 20 },
  { label: '已过期', value: 29 },
  { label: '待排单', value: 40 },
  { label: '排单中', value: 41 },
  { label: '生产中', value: 42 },
  { label: '已发货', value: 70 },
  { label: '退货中', value: 72 },
  { label: '已取消', value: 80 },
  { label: '已完成', value: 90 }
]

export const shopFormData = {
  shopCode: '',
  shopName: ''
}
export const shopForm = [
  {
    label: '店铺名称',
    prop: 'shopName',
    placeholder: '输入店铺名称'
  },
  {
    label: '店铺编码',
    prop: 'shopCode',
    placeholder: '输入店铺编码'
  },

  {
    label: '授权状态',
    prop: 'isAuthorized',
    placeholder: '选择授权状态'
  },
  { prop: 'search' }
]
export const shopTable = [
  {
    label: '店铺名称',
    prop: 'shopName',
    search: true,
    width: 240,
    slot: true
  },
  {
    label: '',
    prop: 'tagStatus',
    search: true,
    width: 80,
    slot: true
  },
  {
    label: '店铺编码',
    search: true,
    prop: 'shopCode'
  },
  {
    label: '所属平台',
    search: true,
    prop: 'shopType',
    slot: true
  },
  {
    slot: true,
    label: '自动同步订单',
    prop: 'isAutoSyncOrder'
  },

  {
    slot: true,
    label: '自动同步运单号',
    prop: 'isAutoSyncWaybill'
  },

  {
    label: '授权状态',
    prop: 'isAuthorized',
    slot: true,
    search: true
  },
  { label: '操作', prop: 'operation5' }
]

export const prototypeForm = [
  { label: '款式', placeholder: '输入款式', prop: 'styleName' },
  { label: '原型分类', prop: 'platformCategoryId' },
  { label: '档位分类', prop: 'levelCategoryId' },
  { prop: 'search' }
]
export const prototypeColumn = [
  { label: '原型名称/图片', prop: 'title' },
  {
    label: '原型分类',
    prop: 'productCategoryId',
    formatter(row) {
      return row.productCategory?.name
    }
  },
  { label: '款式', prop: 'styleName' },
  { label: '尺码', prop: 'sizeName' },
  { label: '价格', prop: 'priceSlot' },
  { label: '操作', prop: 'assignOperation' }
]

export const pictureForm = [
  { label: '图片名称', placeholder: '输入图片名称', prop: 'titleList' },
  { label: '图片分类', prop: 'categoryId' },
  { prop: 'search' }
]
export const pictureColumn = [
  { label: '图片名称', prop: 'title', hideImg: true },
  { label: '图片', prop: 'thumbnailPath' },
  { label: '图片分类', prop: 'categoryName' },
  { label: '操作', prop: 'assignOperation' }
]

export const radioGroupBtn = [
  {
    label: '子账号管理',
    url: '/externaladmin/authService/user/childrenListByMainId',
    formOption: accountForm,
    tableColumn: accountTable,
    permission: checkPermission('externaladmin:user:resellerPage:childrenManage'),
    childPermission: {
      search: checkPermission('externaladmin:user:resellerPage:search'),
      edit: checkPermission('externaladmin:user:resellerPage:edit')
    }
  },
  {
    label: '分销商图片管理',
    url: '/externaladmin/productService/image/list',
    formOption: photoForm,
    tableColumn: phoTable,
    permission: checkPermission('externaladmin:user:resellerPage:imageManage'),
    childPermission: {
      search: checkPermission('externaladmin:user:resellerPage:picManage:search'),
      disable: checkPermission('externaladmin:user:resellerPage:picManage:disable')
      // cancelDisable: ['externaladmin:user:resellerPage:picManage:cancelDisable']
      // add: ['externaladmin:system:roles:adminRole:add'],
      // edit: ['externaladmin:system:roles:adminRole:edit'],
      // assign: ['externaladmin:system:roles:adminRole:assign'],
      // delete: ['externaladmin:system:roles:adminRole:delete']
    }
  },
  {
    label: '分销商订单管理',
    url: '/externaladmin/orderService/externalOrder/orderList',
    formOption: orderForm,
    tableColumn: orderTable,
    permission: checkPermission('externaladmin:user:resellerPage:orderManage'),
    childPermission: {
      search: checkPermission('externaladmin:user:resellerPage:orderManage:search'),
      update: checkPermission('externaladmin:user:resellerPage:orderManage:update'),
      edit: checkPermission('externaladmin:user:resellerPage:orderManage:edit'),
      detail: checkPermission('externaladmin:user:resellerPage:orderManage:detail')
      // add: ['externaladmin:system:roles:adminRole:add'],
      // edit: ['externaladmin:system:roles:adminRole:edit'],
      // assign: ['externaladmin:system:roles:adminRole:assign'],
      // delete: ['externaladmin:system:roles:adminRole:delete']
    }
  },
  {
    label: '分销商定制产品管理',
    url: '/externaladmin/productService/productPrototypeCollection/productCustomList',
    formOption: proForm,
    tableColumn: proTable,
    permission: checkPermission('externaladmin:user:resellerPage:productManage'),
    childPermission: {
      search: checkPermission('externaladmin:user:resellerPage:designedProductManage:search'),
      detail: checkPermission('externaladmin:user:resellerPage:designedProductManage:getProductInformation')
      // add: ['externaladmin:system:roles:adminRole:add'],
      // edit: ['externaladmin:system:roles:adminRole:edit'],
      // assign: ['externaladmin:system:roles:adminRole:assign'],
      // delete: ['externaladmin:system:roles:adminRole:delete']
    }
  },
  {
    label: '分销商店铺管理',
    url: '/externaladmin/shopService/shop/list',
    formOption: shopForm,
    tableColumn: shopTable,
    permission: checkPermission('externaladmin:user:resellerPage:shop'),
    childPermission: {
      search: checkPermission('externaladmin:user:resellerPage:shop:search'),
      hasEidt: checkPermission('externaladmin:user:store:edit'),
      hasDisable: checkPermission('externaladmin:user:store:forbid'),
      hasEnable: checkPermission('externaladmin:user:store:enable')
    }
  },
  {
    label: '指定原型',
    url: '/externaladmin/prototypeService/productPrototype/list',
    formOption: prototypeForm,
    tableColumn: prototypeColumn,
    handleTableData(data) {
      data.forEach((row) => {
        row.title = row.chineseName
        row.thumbnailPath = getValueFromObj(row, 'styleList[0].styleDisplayImageList[0].displayImagePath')
        row.customProductList = row.styleList?.map((item) => {
          item.styleName = item.name
          item.customSpecificProductList = row.sizeList || []
          return item
        })
      })
      return data
    },
    assignApi({ mainAccountUidList, value }) {
      return userDistributorPrototypeSet({
        mainAccountUidList,
        prototypeIdList: value
      })
    },
    unassignApi({ userId, value }) {
      return userDistributorPrototypeBulkDelete({
        userId,
        prototypeIdList: value
      })
    },
    permission: checkPermission('externaladmin:user:resellerPage:prototype'),
    childPermission: {
      search: checkPermission('externaladmin:user:resellerPage:prototype:search'),
      unassign: checkPermission('externaladmin:user:resellerPage:prototype:unassign'),

      checkAllData: true,
      viewPrice: checkPermission('externaladmin:user:resellerPage:prototype:viewPrice'),
      setPrice: checkPermission('externaladmin:user:resellerPage:prototype:setPrice'),
      batchSetPrice: checkPermission('externaladmin:user:resellerPage:prototype:batchSetPrice'),
      exportPriceExcel: checkPermission('externaladmin:user:resellerPage:prototype:exportPriceExcel'),
      assignPrototype: checkPermission('externaladmin:user:resellerPage:prototype:assign'),
      batchCancel: checkPermission('externaladmin:user:resellerPage:prototype:batchCancel'),
      toggleProtoGroup: checkPermission('externaladmin:user:resellerPage:prototype:toggleProtoGroup')
    }
  },
  {
    label: '指定图片',
    url: '/externaladmin/productService/image/openList',
    formOption: pictureForm,
    tableColumn: pictureColumn,
    assignApi({ mainAccountUidList, value }) {
      return userDistributorImageDataSet({
        mainAccountUidList,
        imageIdList: value
      })
    },
    unassignApi({ userId, value }) {
      return userDistributorImageBulkDelete({
        userId,
        imageIdList: value
      })
    },
    permission: checkPermission('externaladmin:user:resellerPage:picture'),
    childPermission: {
      search: checkPermission('externaladmin:user:resellerPage:picture:search'),
      unassign: checkPermission('externaladmin:user:resellerPage:picture:unassign'),

      checkAllData: false,
      assignPicture: checkPermission('externaladmin:user:resellerPage:picture:assign'),
      batchCancel: checkPermission('externaladmin:user:resellerPage:picture:batchCancel'),
      togglePictureGroup: checkPermission('externaladmin:user:resellerPage:picture:togglePictureGroup')
    }
  },
  {
    label: '实名认证',
    url: '/externaladmin/authService/userCertificateInfo/getByUserId',
    formOption: [{ prop: 'id' }],
    permission: checkPermission('externaladmin:user:resellerPage:AuthenByRealName'),
    childPermission: {
      search: false,
      checkAllData: false,
      isAuth: true
    }
  }
].filter((item) => item.permission)
