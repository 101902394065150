<template>
  <div>
    <ColorTextBtn v-loading="loading" element-loading-spinner :type="type" class="line" @click="showDialog">{{
      text
    }}</ColorTextBtn>
    <el-dialog title="填写禁用原因" :visible.sync="dialogVisible" width="30%" align="left">
      <el-form :model="form" label-width="50px" :rules="ruleForm" ref="form">
        <el-form-item label="原因" prop="reason">
          <el-input v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="disablePicture('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { disablePictureApi, enablePictureApi } from '../resellerAPI'
export default {
  props: {
    data: {
      type: Object,
      default: {}
    },
    sup_this: {
      typeof: Object,
      default: {}
    }
  },
  data() {
    return {
      datas: this.data,
      text: this.data.disable == 0 ? '禁用' : '启用',
      type: this.data.disable == 0 ? 'danger' : 'success',
      dialogVisible: false,
      form: {
        reason: '',
        idList: []
      },
      ruleForm: {
        reason: [{ required: true, message: '禁用图片原因必填', trigger: 'blur' }]
      },
      loading: false
    }
  },
  components: {},
  methods: {
    disablePicture(refName) {
      console.log('datas', this.datas)
      this.form.idList.push(this.datas.id)
      this.$refs[refName].validate(async (valid) => {
        if (valid) {
          await disablePictureApi(this.form)
            .then(() => {
              this.$message.success('禁用成功')
              this.sup_this.init()
            })
            .catch(() => {
              this.$message.error('禁用失败')
              this.sup_this.init()
            })
          this.dialogVisible = false
        } else {
          return false
        }
      })
    },
    async showDialog() {
      if (this.type == 'success') {
        this.loading = true
        this.form.idList.push(this.datas.id)
        await enablePictureApi({ idList: this.form.idList })
          .then(() => {
            this.loading = false
            this.$message.success('启用成功')
            this.sup_this.init()
          })
          .catch(() => {
            this.loading = false
            this.$message.error('启用失败')
            this.sup_this.init()
          })
      } else {
        this.dialogVisible = true
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
