<template>
  <div class="changeStateBtn line">
    <ColorTextBtn type="text" @click="showChangeStateDialogVisible" style="font-size: 12px">修改订单状态</ColorTextBtn>
    <BaseDialog title="修改订单状态" :dialogVisible.sync="changeStateDialogVisible" width="30%">
      <div class="changeStateDialogContent" style="padding-top: 12px">
        <span>订单编号: {{ data.orderCode }}</span>
        <span>物流渠道: {{ getLabel('expressCompanyId', data.expressCompanyId) }}</span>
        <span>运单号: {{ data.expressWaybillCode }}</span>
        <span>业务员: {{ data.userName }}</span>
        <div style="margin: 16px 0 9px 0">
          当前状态:
          <span class="changeStateBtn-color">{{ getLabel('orderStatus', data.orderStatus) }}</span>
        </div>
      </div>
      <el-form class="changeStateBtn-from" size="mini">
        <el-form-item label="修改订单状态" :model="data">
          <el-select v-model="data.orderStatus" placeholder="请选择">
            <el-option
              v-for="({ label, value }, index) in orderStatus"
              :key="index"
              :label="label"
              :value="value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancellChange">取 消</el-button>
        <baseButton size type="primary" @click="changeStateDialog">确 定</baseButton>
      </span>
    </BaseDialog>
  </div>
</template>

<script>
import { listUpdate } from '@/api/order/orderListData'
import { getLabel } from '@/components/avue/utils/util'
import { mapGetters } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      changeStateDialogVisible: false
    }
  },
  computed: {
    ...mapGetters(['orderStatus'])
  },
  created() {},
  methods: {
    getLabel,
    showChangeStateDialogVisible() {
      this.changeStateDialogVisible = true
    },
    cancellChange() {
    this.changeStateDialogVisible = false
    this.sup_this.init()
    },
    async changeStateDialog() {
      const { code } = await listUpdate({
        orderStatus: this.data.orderStatus,
        id: this.data.id
      })
      if (code == 0) {
        if (this.sup_this) {
          this.sup_this.init()
        }
        this.$message.success('更改状态成功')
      } else {
        this.$message.error('更改状态失败')
      }
      this.changeStateDialogVisible = false
    }
  }
}
</script>

<style lang="scss">
.changeStateBtn {
  display: inline-block;
}
.changeStateDialogContent {
  background-color: #ebf0f8;
  padding: 5px;
  span {
    margin-right: 20px;
  }
}
.changeStateBtn-from {
  margin-top: 20px;
}

.changeStateBtn-color {
  color: orange;
}
</style>
