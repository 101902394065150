<template>
  <div class="inline-block">
    <ColorTextBtn class="line" @click="showDialog">{{ title }} </ColorTextBtn>
    <BaseDialog :append-to-body="true" :dialogVisible.sync="dialogVisible" title="温馨提示" width="592px">
      <p>{{ desc }}</p>
      <p v-if="initData.isEnabled == 1" style="font-size: 12px; color: #bec5db; line-height: 16px">
        禁用后将无法同步第三方平台店铺订单，请谨慎考虑
      </p>
      <template #footer>
        <LoadingBtn size="small" @click="cancel">取消</LoadingBtn>
        <LoadingBtn size="small" :loading="loading" type="primary" @click="doSubmit">确定</LoadingBtn>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { deepClone } from '@/components/avue/utils/util'
import { commonEheaderMixin } from '@/mixins'
import { disableShop as disable, enableShop as enable } from '@/api/shop/manageApi'

export default {
  mixins: [commonEheaderMixin],
  props: {
    initData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sup_this: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      desc: ''
    }
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(n) {
        let value = deepClone(this.initData)
        if (value?.isEnabled == 0) {
          this.desc = `是否重新启用${value?.shopName}`
        } else {
          this.desc = `是否禁用${value?.shopName}`
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    cancel() {
      this.dialogVisible = false
    },
    async doSubmit() {
      let params = {
        idList: [this.initData.id]
      }
      let [err, res] = []
      if (this.initData.isEnabled == 0) {
        // 启用
        ;[err, res] = await awaitWrap(enable(params))
      } else {
        ;[err, res] = await awaitWrap(disable(params))
      }

      if ($SUC(res)) {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
        this.sup_this && this.sup_this.init()
        this.cancel()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 20px !important;
}
</style>
